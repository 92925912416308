import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Hulu-bulu, side ikke fundet" />
    <h1>404: Side ikke fundet</h1>
    <p>Hulu-bulu, Lotte, hvor er du henne?</p>
  </Layout>
)

export default NotFoundPage
